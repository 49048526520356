import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import getSingleStream from '../../../../API/streams/getSingleStream';
import getOrCreateRtmpEndpoint from '../../../../API/medialive/getOrCreateRtmpEndpoint';
import Contexts from '../../../../Contexts';
import ConfirmationModal from '../../../../Components/ConfirmationModel';
import deleteAllMergedAndIndividualClips from '../../../../API/streams/deleteAllMergedAndIndividualClips';

const POINTES_TABLE_OVERRIDE_SUPPORTED_SPORTS = ['cricket'];

export default function SelectedStream() {
  const classes = useStyles();
  const history = useHistory();
  const { streamId } = useParams();
  const [stream, setStream] = useState(null);
  const [awsMediaLive, setAwsMediaLive] = useState(null);
  const [
    deleteClipConfirmationModalOpen,
    setDeleteClipConfirmationModalOpen,
  ] = useState(false);

  const context = useContext(Contexts);
  const { showLoadingSpinner } = context;

  useEffect(() => {
    const loadStreamData = async () => {
      const fetchedStream = await getSingleStream(streamId);
      setStream(fetchedStream);
    };

    loadStreamData();
  }, [streamId]);

  useEffect(() => {
    const loadMedialiveData = async () => {
      try {
        const fetchedData = await getOrCreateRtmpEndpoint({
          streamId,
          create_new: false,
          is_overlay_enabled: false,
        });
        setAwsMediaLive(fetchedData);
      } catch (error) {
        // 404 not found
      }
    };

    loadMedialiveData();
  }, []);

  const handleGenerateRTMP = () => {
    const generateRTMP = async () => {
      showLoadingSpinner(true);
      try {
        const fetchedData = await getOrCreateRtmpEndpoint({
          streamId,
          create_new: true,
          is_overlay_enabled: false,
        });
        setAwsMediaLive(fetchedData);
      } catch (error) {}
      showLoadingSpinner(false);
    };

    generateRTMP();
  };

  const handleDeleteAllMergedAndIndividualClips = async () => {
    try {
      await deleteAllMergedAndIndividualClips(streamId);
    } catch (err) {
      console.error(err);
    } finally {
      setDeleteClipConfirmationModalOpen(false);
    }
  };

  const onMangeDetailsBTN = () => {
    history.push({
      pathname: `/streams/${streamId}/details`,
    });
  };

  const handleGenerateReplayMp4 = () => {
    history.push({
      pathname: `/streams/${streamId}/generate-replay-mp4`,
    });
  };

  const onManageTeamsBTN = () => {
    history.push({
      pathname: `/streams/${streamId}/teams`,
    });
  };

  const onMangeLineUpBTN = () => {
    history.push({
      pathname: `/streams/${streamId}/lineups`,
    });
  };

  const onLinkedPlayersClicked = () => {
    history.push({
      pathname: `/streams/${streamId}/linked-players`,
    });
  };

  const onGenerateAutoClipsClips = () => {
    history.push({
      pathname: `/streams/${streamId}/generate-auto-clips`,
    });
  };

  const onGenerateAutoClipsClipsFast = () => {
    history.push({
      pathname: `/streams/${streamId}/generate-auto-clips-fast`,
    });
  };

  const onManageSfaMapping = () => {
    history.push({
      pathname: `/streams/${streamId}/sfa-mapping`,
    });
  };

  const onOverridePointsTable = () => {
    history.push({
      pathname: `/streams/${streamId}/override-points-table`,
    });
  };

  const onManageOTTAds = () => {
    history.push({
      pathname: `/streams/${streamId}/ott-ads`,
    });
  };

  // const renderRtmpGenerator = () => {
  //   if (!stream) return <></>;

  //   const { article_type, is_complete } = stream;

  //   if (is_complete) return <></>;

  //   if (article_type === 'youtube') {
  //     return (
  //       <Typography variant="caption">
  //         RTMP link cant be generated for youtube streams
  //       </Typography>
  //     );
  //   }

  //   if (awsMediaLive) {
  //     const {
  //       is_input_expired,
  //       rtmp_server_url,
  //       rtmp_stream_key,
  //     } = awsMediaLive;
  //     return (
  //       <>
  //         <Typography variant="h4">RTMP Details</Typography>
  //         <Typography variant="body1">SERVER: {rtmp_server_url}</Typography>
  //         <Typography variant="body1">STREAM KEY: {rtmp_stream_key}</Typography>
  //         {is_input_expired && (
  //           <Typography variant="caption">
  //             NOTE: THIS ENDPOINT HAS EXPIRED
  //           </Typography>
  //         )}
  //         <Link
  //           target="_blank"
  //           variant="body2"
  //           href="https://gist.github.com/gomesLuis479SV/b1c3d0301170fb581e926750af9fccdd"
  //         >
  //           [Read This]: RMTP Usage Guide
  //         </Link>
  //       </>
  //     );
  //   }

  //   return (
  //     <>
  //       <Button
  //         className={classes.buttons}
  //         variant="contained"
  //         onClick={handleGenerateRTMP}
  //       >
  //         Generate RTMP Link
  //       </Button>
  //     </>
  //   );
  // };

  const renderRtmpGenerator = () => {
    return (
      <Typography variant="caption">
        RTMP generation here is now DEPRECATED, PLEASE USE cloud prod V2
        Streaming
      </Typography>
    );
  };

  const renderMp4GenerationOption = () => {
    if (!stream) return <></>;

    const { article_type, is_complete, media_path, v2_media_path } = stream;

    const isMp4GenerationPossible =
      is_complete &&
      article_type === 'stream' &&
      media_path &&
      media_path.startsWith('http') &&
      media_path.endsWith('.m3u8');

    const isV2MediaPathCase = is_complete && v2_media_path;

    if (!isMp4GenerationPossible && !isV2MediaPathCase) return <></>;

    return (
      <>
        <Button
          className={classes.buttons}
          variant="contained"
          onClick={handleGenerateReplayMp4}
        >
          Generate Replay MP4
        </Button>
      </>
    );
  };

  const renderOptions = () => (
    <>
      <Button
        className={classes.buttons}
        variant="contained"
        onClick={onMangeDetailsBTN}
      >
        Manage Details
      </Button>

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={onManageTeamsBTN}
      >
        Manage Teams
      </Button>

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={onMangeLineUpBTN}
      >
        Line-ups
      </Button>

      {renderRtmpGenerator()}

      {renderMp4GenerationOption()}

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={onLinkedPlayersClicked}
      >
        Linked Players
      </Button>

      {/* <Button
        className={classes.buttons}
        variant="contained"
        onClick={onGenerateAutoClipsClips}
      >
        Generate Auto-Clips
      </Button> */}

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={onGenerateAutoClipsClipsFast}
      >
        Generate Auto-Clips-FAST
      </Button>

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={onManageSfaMapping}
      >
        Manage SFA Mapping
      </Button>

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={onManageOTTAds}
      >
        Manage OTT Ads
      </Button>

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={() => setDeleteClipConfirmationModalOpen(true)}
        style={{ backgroundColor: '#ff0000', color: '#fff' }}
      >
        DELETE ALL MERGED & INDIVIDUAL CLIPS
      </Button>

      {POINTES_TABLE_OVERRIDE_SUPPORTED_SPORTS.includes(stream?.category) && (
        <Button
          className={classes.buttons}
          variant="contained"
          onClick={onOverridePointsTable}
        >
          OVERRIDE POINTS TABLE
        </Button>
      )}

      <ConfirmationModal
        open={deleteClipConfirmationModalOpen}
        title="DELETE ALL MERGED & INDIVIDUAL CLIPS"
        description="Are you sure? This will delete all the merged and individual clips. This action can not be undone"
        onClose={() => setDeleteClipConfirmationModalOpen(false)}
        handleCancel={() => setDeleteClipConfirmationModalOpen(false)}
        handleConfirm={() => handleDeleteAllMergedAndIndividualClips()}
        askPassword={false}
        confirmText="Yes, Delete"
      />
    </>
  );

  return <div className={classes.container}>{renderOptions()}</div>;
}
