import axiosInstance from '../../axiosInstance';
import type { EventRangeRequest, ViewsOverrideConfig } from './types';

const API_ENDPOINT = '/api/admin/v2/views-override';

export const getViewsOverrideConfig = async (): Promise<ViewsOverrideConfig> => {
    const res = await axiosInstance.get(`${API_ENDPOINT}`);
    return res.data.data;
};

export const updateEventRangeConfig = async (args: EventRangeRequest): Promise<ViewsOverrideConfig> => {
    const res = await axiosInstance.patch(`${API_ENDPOINT}`, args);
    return res.data.data;
};

export const deleteEventRangeConfig = async (eventId: string): Promise<ViewsOverrideConfig> => {
    const res = await axiosInstance.delete(`${API_ENDPOINT}/${eventId}`);
    return res.data.data;
};

export const toggleViewsOverrideConfigEnabled = async (enabled: boolean): Promise<ViewsOverrideConfig> => {
    const res = await axiosInstance.patch(`${API_ENDPOINT}/is-enabled`, {
        is_enabled: enabled
    });
    return res.data.data;
}