import React from 'react';
import { useFormikContext } from 'formik';
import { Button } from '@material-ui/core';

const FormikSubmit = ({ submitText, fullWidth = false, size = 'medium' }) => {
  const { submitForm, isSubmitting } = useFormikContext();

  const handleClick = () => submitForm();

  return (
    <Button
      disabled={isSubmitting}
      fullWidth={fullWidth}
      variant="contained"
      onClick={handleClick}
      size={size}
    >
      {submitText}
    </Button>
  );
};

export default FormikSubmit;
