/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import MenuIcon from '@material-ui/icons/Menu';
import TvRounded from '@material-ui/icons/TvRounded';
import MatchIcon from '@material-ui/icons/SportsFootball';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import TeamsIcon from '@material-ui/icons/People';
import ArticlesIcon from '@material-ui/icons/OndemandVideoRounded';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import TenantIcon from '@material-ui/icons/GroupAdd';
import Home from '@material-ui/icons/Home';
import {
  Brightness2 as DarkModeIcon,
  WbSunny as LightModeIcon,
} from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import Contexts from '../../Contexts';
import useStyles from './styles';
import AppDrawer from '../Drawer/Drawer';
import {
  CHANNEL_MANAGEMENT,
  MATCH_MANAGEMENT,
  ATHLETEMANAGMENT,
  TEAMS,
  EVENTS,
  STREAMS,
  ARTICLES,
  PERMISSIONS,
  HEADER_CONTENT,
  APPROVALS,
  STREAMING_SERVER,
  USERS,
  MANAGED_HOME_FEED,
  JIO_SPORTS_HUB_FEED,
  PIXELLOT_TENANTS,
  SERVER_COSTS,
  STREAMS_PIX_HIGHLIGHTS_VAS,
  CP_FIXED_LINKS,
  VIEWS_OVERRIDE,
  OTT_ADS,
} from '../../Utils/routeNames';
import { AUTHORITIES } from '../../Utils/authorities';
import { THEME_PREFERENCE } from '../../API/constants';

const nameIconArray = [
  {
    name: 'Home',
    icon: <Home />,
    routeName: '',
  },
  {
    name: 'Channel',
    icon: <TvRounded />,
    routeName: CHANNEL_MANAGEMENT,
  },
  {
    name: 'Match',
    icon: <MatchIcon />,
    routeName: MATCH_MANAGEMENT,
  },
  {
    name: 'Athlete',
    icon: <DirectionsRun />,
    routeName: ATHLETEMANAGMENT,
    // authorities: [AUTHORITIES.PLAYER.READ],
  },
  {
    name: 'Teams',
    icon: <TeamsIcon />,
    routeName: TEAMS,
  },
  // {
  //   name: 'Tournament',
  //   icon: <TeamsIcon />,
  //   routeName: TOURNAMENT,
  // },
  {
    name: 'Events',
    icon: <TeamsIcon />,
    routeName: EVENTS,
  },
  {
    name: 'Streams',
    icon: <TeamsIcon />,
    routeName: STREAMS,
  },
  {
    name: 'Streams Pix HL VAS',
    icon: <TeamsIcon />,
    routeName: STREAMS_PIX_HIGHLIGHTS_VAS,
  },
  {
    name: 'Articles',
    icon: <ArticlesIcon />,
    routeName: ARTICLES,
    // authorities: [AUTHORITIES.ARTICLE.READ],
  },
  {
    name: 'Header Content',
    icon: <ArticlesIcon />,
    routeName: HEADER_CONTENT,
    // authorities: [AUTHORITIES.ARTICLE.READ],
  },
  {
    name: 'Approvals (New)',
    icon: <TvRounded />,
    routeName: APPROVALS,
  },
  // {
  //   name: 'Streaming Server',
  //   icon: <TvRounded />,
  //   routeName: STREAMING_SERVER,
  // },
  {
    name: 'Users',
    icon: <TeamsIcon />,
    routeName: USERS,
  },
  {
    name: 'Permission',
    icon: <ArticlesIcon />,
    routeName: PERMISSIONS,
    authorities: [
      AUTHORITIES.USER.AUTHORITIES.READ,
      AUTHORITIES.USER.AUTHORITIES.WRITE,
      AUTHORITIES.USER.READ,
    ],
  },
  {
    name: 'Managed Home Feed',
    icon: <DynamicFeedIcon />,
    routeName: MANAGED_HOME_FEED,
  },
  {
    name: 'Jio Sports Hub Feed',
    icon: <DynamicFeedIcon />,
    routeName: JIO_SPORTS_HUB_FEED,
  },
  {
    name: 'Pixellot Tenants',
    icon: <TenantIcon />,
    routeName: PIXELLOT_TENANTS,
  },

  {
    name: 'CP Fixed Links',
    icon: <ArticlesIcon />,
    routeName: CP_FIXED_LINKS,
  },

  {
    name: 'Server Costs',
    icon: <DynamicFeedIcon />,
    routeName: SERVER_COSTS,
  },
  {
    name: 'Views Override',
    icon: <VisibilityOutlinedIcon />,
    routeName: VIEWS_OVERRIDE,
  },
  {
    name: 'OTT Ads',
    icon: <FeaturedVideoIcon />,
    routeName: OTT_ADS,
  },
];

const TopAppBar = ({ history }) => {
  // to setInitial App bar name
  const context = useContext(Contexts);
  const {
    userDoc,
    setLoginStatusAndToken,
    isDarkMode,
    setIsDarkMode,
  } = context;

  const handleThemeChange = () => {
    if (isDarkMode) {
      localStorage.setItem(THEME_PREFERENCE, 'LIGHT');
      setIsDarkMode(false);
    } else {
      localStorage.setItem(THEME_PREFERENCE, 'DARK');
      setIsDarkMode(true);
    }
  };

  const getInitialPathName = () => {
    let initialPathName = nameIconArray[0].name;
    let pathname = history.location.pathname.split('/');

    pathname = pathname[pathname.length - 1];

    for (let i = 0; i < nameIconArray.length; i += 1) {
      const data = nameIconArray[i];
      if (data.routeName === pathname) {
        initialPathName = nameIconArray[i].name;

        break;
      }
    }
    return initialPathName;
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [screenName, setScreenName] = useState(getInitialPathName());

  const toggleSideBar = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSideBarItemClick = (name, routeName) => {
    setScreenName(name);
    history.push(`/${routeName}`);
  };

  const handleLogout = () => {
    setLoginStatusAndToken(false);
  };

  // to control rendering of routes based on user doc authorities
  const filterNameIconArrayByAuthority = () => {
    const { authorities: userAuthorities = [] } = userDoc;

    const sideBarItems = nameIconArray.filter(item => {
      const { authorities: routeAuthorities = [] } = item;

      for (let i = 0; i < routeAuthorities.length; i += 1) {
        const authority = routeAuthorities[i];
        if (!userAuthorities.includes(authority)) {
          return false;
        }
      }

      return true;
    });

    return sideBarItems;
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleSideBar}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {screenName}
          </Typography>
          <IconButton onClick={() => handleThemeChange()}>
            {!isDarkMode ? <DarkModeIcon /> : <LightModeIcon />}
          </IconButton>
          <Button onClick={handleLogout} color="inherit">
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <AppDrawer
        open={drawerOpen}
        setOpen={setDrawerOpen}
        nameIconArray={filterNameIconArrayByAuthority()}
        handleSideBarItemClick={handleSideBarItemClick}
      />
    </div>
  );
};

export default withRouter(TopAppBar);
