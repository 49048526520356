import React from 'react';
import { Formik } from 'formik';
import FormFields from './FormFields';
import { EventRangeRequest } from '../../../../API/ViewsOverride/types';

type Props = {
  args: EventRangeRequest;
  handleSubmit: (args: EventRangeRequest) => void;
};

const EventRangeForm: React.FC<Props> = ({ args, handleSubmit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={args}
      onSubmit={values => {
        handleSubmit(values);
      }}
    >
      <FormFields />
    </Formik>
  );
};

export default EventRangeForm;
