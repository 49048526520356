import React from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { AdsData } from '../../../../../API/OttAds/types';
import { Box, IconButton, Switch, Tooltip } from '@material-ui/core';
import { getImageUrl } from '../../../../../Utils/commonUtils';
import DebouncedTextField from '../../../../../Components/CustomComponents/DeboucedTextField';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

type Props = {
  adsData: Record<string, AdsData>;
  handleToggleVisible: (adId: string, f: boolean) => void;
  updateAdCTA: (adId: string, cta: string) => void;
  deleteAd: (adId: string) => void;
  ads_order_ids: string[];
  reorderAds: (ids: string[]) => void;
};

const OttAdsTable: React.FC<Props> = ({
  adsData,
  handleToggleVisible,
  updateAdCTA,
  deleteAd,
  ads_order_ids,
  reorderAds,
}) => {
  const classes = useStyles();

  const onMove = (currentIndex: number, id: string, targetIndex: number) => {
    const newOrderedIdsList = [...ads_order_ids];
    const [removed] = newOrderedIdsList.splice(currentIndex, 1);
    newOrderedIdsList.splice(targetIndex, 0, removed);
    reorderAds(newOrderedIdsList);
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center"></StyledTableCell>
            <StyledTableCell align="left">BANNER</StyledTableCell>
            <StyledTableCell align="center">CTA</StyledTableCell>
            <StyledTableCell align="center">VISIBLE</StyledTableCell>
            <StyledTableCell align="center">ACTIONS</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ads_order_ids.map((adId, mainIndex) => {
            const adData = adsData[adId];

            return (
              <StyledTableRow key={adId}>
                <StyledTableCell>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ minWidth: 60 }}
                  >
                    {mainIndex !== 0 && (
                      <Tooltip title="Move Up">
                        <IconButton
                          size="small"
                          onClick={() => onMove(mainIndex, adId, mainIndex - 1)}
                          style={{ color: 'white' }}
                        >
                          <ExpandLess />
                        </IconButton>
                      </Tooltip>
                    )}
                    {mainIndex !== Object.keys(adsData).length - 1 && (
                      <Tooltip title="Move Down">
                        <IconButton
                          size="small"
                          onClick={() => onMove(mainIndex, adId, mainIndex + 1)}
                          style={{ color: 'white' }}
                        >
                          <ExpandMore />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <img
                    style={{
                      height: 50,
                      maxHeight: 50,
                      objectFit: 'contain',
                    }}
                    src={getImageUrl(adData.ads_img_key)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <DebouncedTextField
                    initialValue={adData.ads_cta}
                    onDebouncedChange={v => updateAdCTA(adId, v)}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Switch
                    checked={adData.is_visible}
                    onChange={e => handleToggleVisible(adId, e.target.checked)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton size="small" onClick={() => deleteAd(adId)}>
                    <DeleteOutlinedIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OttAdsTable;
