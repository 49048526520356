import React, { useEffect, useState } from 'react';
import {
  Box,
  createStyles,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Theme,
  withStyles,
} from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import {
  EventRangeRequest,
  EventViewsRangeConfig,
} from '../../../../API/ViewsOverride/types';

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

type Props = {
  eventId: string;
  config: EventViewsRangeConfig;
  onDelete: () => void;
  onUpdate: (args: EventRangeRequest) => void;
  eventName: string;
};

const ConfigRow: React.FC<Props> = ({
  eventId,
  config,
  onDelete,
  onUpdate,
  eventName,
}) => {
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [startRange, setStartRange] = useState(config.vod_start_range);
  const [endRange, setEndRange] = useState(config.vod_end_range);
  const [liveStartRange, setLiveStartRange] = useState(config.live_start_range);
  const [liveEndRange, setLiveEndRange] = useState(config.live_end_range);

  useEffect(() => {
    setStartRange(config.vod_start_range);
    setEndRange(config.vod_end_range);
  }, [config]);

  return (
    <StyledTableRow>
      <StyledTableCell
        component="th"
        scope="row"
        style={{ textTransform: 'capitalize' }}
      >
        {eventName}
      </StyledTableCell>{' '}
      <StyledTableCell
        component="th"
        scope="row"
        style={{ textTransform: 'capitalize' }}
      >
        {eventId}
      </StyledTableCell>
      <StyledTableCell
        align="center"
        style={{ background: 'rgba(0, 26, 255, 0.2)' }}
      >
        {editModeEnabled && (
          <TextField
            value={startRange}
            onChange={e => setStartRange(parseInt(e.target.value, 10))}
            size="small"
            fullWidth
            variant="outlined"
            type="number"
          />
        )}

        {!editModeEnabled && config.vod_start_range}
      </StyledTableCell>
      <StyledTableCell
        align="center"
        style={{ background: 'rgba(0, 26, 255, 0.2)' }}
      >
        {editModeEnabled && (
          <TextField
            value={endRange}
            onChange={e => setEndRange(parseInt(e.target.value, 10))}
            size="small"
            fullWidth
            variant="outlined"
            type="number"
          />
        )}

        {!editModeEnabled && config.vod_end_range}
      </StyledTableCell>
      <StyledTableCell
        align="center"
        style={{ background: 'rgba(255,0,0,0.2)' }}
      >
        {editModeEnabled && (
          <TextField
            value={liveStartRange}
            onChange={e => setLiveStartRange(parseInt(e.target.value, 10))}
            size="small"
            fullWidth
            variant="outlined"
            type="number"
          />
        )}

        {!editModeEnabled && config.live_start_range}
      </StyledTableCell>
      <StyledTableCell
        align="center"
        style={{ background: 'rgba(255,0,0,0.2)' }}
      >
        {editModeEnabled && (
          <TextField
            value={liveEndRange}
            onChange={e => setLiveEndRange(parseInt(e.target.value, 10))}
            size="small"
            fullWidth
            variant="outlined"
            type="number"
          />
        )}

        {!editModeEnabled && config.live_end_range}
      </StyledTableCell>
      <StyledTableCell align="center">
        <Box
          display="flex"
          alignItems="center"
          style={{ gap: '5px' }}
          width="100%"
          justifyContent="center"
        >
          <IconButton
            size="small"
            onClick={() => setEditModeEnabled(!editModeEnabled)}
          >
            {!editModeEnabled && <EditOutlinedIcon />}
            {editModeEnabled && <CloseOutlinedIcon />}
          </IconButton>

          {editModeEnabled && (
            <IconButton
              size="small"
              onClick={() => {
                onUpdate({
                  event_id: eventId,
                  vod_start_range: startRange,
                  vod_end_range: endRange,
                  live_start_range: liveStartRange,
                  live_end_range: liveEndRange,
                });
                setEditModeEnabled(false);
              }}
            >
              <SaveIcon />
            </IconButton>
          )}

          <IconButton size="small" onClick={() => onDelete()}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default ConfigRow;
