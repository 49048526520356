import React from 'react';
import {
  Box,
  Container,
  LinearProgress,
  Switch,
  Typography,
} from '@material-ui/core';
import useViewsOverride from '../../../customHooks/ViewsOverrideConfig/useViewsOverrideConfig';
import EmptyCard from '../../../Components/Common/EmptyCard';
import EventRangeForm from './Form/EventRangeForm';
import ConfigList from './ConfigList';
import useEvents from '../../../customHooks/Event/useEvents';
import { Alert } from '@material-ui/lab';

const ViewsOverride = () => {
  const {
    viewsOverrideConfig,
    isLoading,
    error,
    submitting,
    handleDeleteEventRangeConfig,
    handleUpdateEventRangeConfig,
    toggleEnabled,
  } = useViewsOverride();

  const { events, error: eventsError, isLoading: isEventsLoading } = useEvents({
    eventIds: viewsOverrideConfig
      ? Object.keys(viewsOverrideConfig.config)
      : [],
  });

  if (!viewsOverrideConfig || error || eventsError || isEventsLoading)
    return null;

  return (
    <Box>
      <LinearProgress
        color="secondary"
        style={{ opacity: isLoading || submitting ? 1 : 0 }}
      />

      <Container maxWidth="lg">
        <Box
          border="1px solid #333"
          bgcolor="rgba(0,0,0,0.05)"
          borderRadius={5}
          p={1}
          py={0}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
          boxShadow={3}
        >
          <Typography variant="caption">VIEWS OVERRIDE</Typography>

          <Switch
            color="primary"
            checked={viewsOverrideConfig.is_enabled}
            onChange={e => toggleEnabled(e.target.checked)}
          />
        </Box>

        <Box p={1} py={2} border="1px solid #333" mt={2} borderRadius={5}>
          <EventRangeForm
            args={{
              event_id: '',
              vod_start_range: 0,
              vod_end_range: 0,
              live_start_range: 0,
              live_end_range: 0,
            }}
            handleSubmit={handleUpdateEventRangeConfig}
          />
        </Box>

        <Box mt={2}>
          <Alert severity="error">
            Note: once the event is done you can delete that row from the table,
            it reduces extra processing on server.
          </Alert>
          <Box mt={2} />
          <ConfigList
            config={viewsOverrideConfig.config}
            deleteEventConfig={handleDeleteEventRangeConfig}
            onUpdate={handleUpdateEventRangeConfig}
            events={events}
          />
        </Box>

        {Object.keys(viewsOverrideConfig.config).length === 0 && (
          <Box width="100%" mt={2}>
            <EmptyCard label="No Override Config Available" />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default ViewsOverride;
