import axiosInstance from '../../axiosInstance';
import { ConfigLevel } from './types';

const API_ENDPOINT = '/api/admin/v2/ott-ads';

export const getOTTAds = async (
  linkedEntityId: string,
  configLevel: ConfigLevel,
) => {
  const resp = await axiosInstance.get(
    `${API_ENDPOINT}/${configLevel}/${linkedEntityId}`,
  );

  return resp.data.data;
};

export const updateOTTAdsIsEnabled = async (
  linkedEntityId: string,
  configLevel: ConfigLevel,
  enabled: boolean,
) => {
  const resp = await axiosInstance.patch(
    `${API_ENDPOINT}/${configLevel}/${linkedEntityId}/is-enabled`,
    {
      is_enabled: enabled,
    },
  );

  return resp.data.data;
};

export const deleteOTTSpecificAd = async (
  linkedEntityId: string,
  adId: string,
) => {
  const resp = await axiosInstance.delete(
    `${API_ENDPOINT}/${linkedEntityId}/ads/${adId}`,
  );

  return resp.data.data;
};

export const updateOTTSpecificAdVisible = async (
  linkedEntityId: string,
  adId: string,
  visible: boolean,
) => {
  const resp = await axiosInstance.patch(
    `${API_ENDPOINT}/${linkedEntityId}/ads/${adId}/is-visible`,
    {
      is_enabled: visible,
    },
  );

  return resp.data.data;
};

export const createNewOTTAd = async (
  linkedEntityId: string,
  configLevel: ConfigLevel,
  image: File,
) => {
  const formData = new FormData();
  formData.append('image', image);

  const resp = await axiosInstance.post(
    `${API_ENDPOINT}/${configLevel}/${linkedEntityId}/ads`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );

  return resp.data.data;
};

export const updateOTTAdsCTA = async ({
  linkedEntityId,
  configLevel,
  adId,
  cta,
}: {
  linkedEntityId: string;
  configLevel: ConfigLevel;
  adId: string;
  cta: string;
}) => {
  const resp = await axiosInstance.patch(
    `${API_ENDPOINT}/${configLevel}/${linkedEntityId}/ads/${adId}`,
    {
      ad_cta: cta,
    },
  );

  return resp.data.data;
};

export const reorderOttAds = async (
  linkedEntityId: string,
  configLevel: ConfigLevel,
  adsOrderIds: string[],
) => {
  const resp = await axiosInstance.patch(
    `${API_ENDPOINT}/${configLevel}/${linkedEntityId}/reorder-ads`,
    {
      ads_order_ids: adsOrderIds,
    },
  );

  return resp.data.data;
};
