import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';
import EventsSearch from './EventsSearch';
import AddEvent from './AddEvent';
import EventDetails from './EventsDetails';
import EventOTTAds from './EventOTTAds';

export default function EventsManagement() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <EventsSearch />
      </Route>
      <Route exact path={`${path}/add`}>
        <AddEvent />
      </Route>
      <Route exact path={`${path}/:eventId`}>
        <EventDetails />
      </Route>
      <Route exact path={`${path}/:eventId/ott-ads`}>
        <EventOTTAds />
      </Route>
    </Switch>
  );
}
