import { create } from 'zustand';

type Store = {
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
  isLoading: boolean;
  setIsLoading: (open: boolean) => void;
};

const useOTTAdsStore = create<Store>(set => ({
  selectedCategory: 'all_sports',
  setSelectedCategory: category =>
    set(() => ({ selectedCategory: category, selectedSectionKey: '' })),
  isLoading: false,
  setIsLoading: isLoading => set(() => ({ isLoading })),
}));

export default useOTTAdsStore;
