import React from 'react';
import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import {
  EventRangeRequest,
  EventViewsRangeConfig,
} from '../../../../API/ViewsOverride/types';
import { withStyles } from '@material-ui/styles';
import ConfigRow from './ConfigRow';

type Props = {
  config: Record<string, EventViewsRangeConfig>;
  deleteEventConfig: (id: string) => void;
  onUpdate: (args: EventRangeRequest) => void;
  events: Record<string, any>;
};

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const ConfigList: React.FC<Props> = ({
  config,
  deleteEventConfig,
  onUpdate,
  events,
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Event Name</StyledTableCell>
            <StyledTableCell>Event ID</StyledTableCell>
            <StyledTableCell align="center">VOD Start Range</StyledTableCell>
            <StyledTableCell align="center">VOD End Range</StyledTableCell>
            <StyledTableCell align="center">Live Start Range</StyledTableCell>
            <StyledTableCell align="center">Live End Range</StyledTableCell>
            <StyledTableCell align="center">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(config).map((eventId: string) => (
            <ConfigRow
              key={eventId}
              eventId={eventId}
              config={config[eventId]}
              onDelete={() => deleteEventConfig(eventId)}
              onUpdate={onUpdate}
              eventName={events[eventId]?.name || eventId}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ConfigList;
