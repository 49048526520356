import { useEffect, useState } from 'react';
import getEvents from '../../API/events/getEvents';

type Props = {
  eventIds: string[];
};

const useEvents = ({ eventIds = [] }: Props) => {
  const [events, setEvents] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (eventIds.length === 0) return;

    setIsLoading(true);

    const fetchEvents = async () => {
      try {
        const { events }: { events: any[] } = await getEvents(
          '',
          '',
          '',
          '',
          '',
          1,
          '',
          '',
          '',
          eventIds.join(','),
        );

        if (events) {
          const eventsMap = events.reduce(
            (acc: Record<string, any>, event: any) => ({
              ...acc,
              [event.event_id]: event,
            }),
            {},
          );
          setEvents(eventsMap);
        } else {
          setEvents({});
        }
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, [JSON.stringify(eventIds)]);

  return {
    events,
    isLoading,
    error,
  };
};

export default useEvents;
