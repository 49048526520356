import React from 'react';
import { Grid } from '@material-ui/core';
import FormikTextInput from '../../../../Components/Forms/MuiFormikComponents/FormikTextInput';
import FormikSubmit from '../../../../Components/Forms/MuiFormikComponents/FormikSubmit';

const FormFields = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={2}>
        <FormikTextInput
          name="event_id"
          label="Event ID"
          multiline={false}
          variant="outlined"
          size="small"
        />
      </Grid>

      <Grid item xs={12} lg={2}>
        <FormikTextInput
          type="number"
          name="vod_start_range"
          label="VOD Start Range"
          multiline={false}
          variant="outlined"
          size="small"
        />
      </Grid>

      <Grid item xs={12} lg={2}>
        <FormikTextInput
          type="number"
          name="vod_end_range"
          label="VOD End Range"
          multiline={false}
          variant="outlined"
          size="small"
        />
      </Grid>

      <Grid item xs={12} lg={2}>
        <FormikTextInput
          type="number"
          name="live_start_range"
          label="Live Start Range"
          multiline={false}
          variant="outlined"
          size="small"
        />
      </Grid>

      <Grid item xs={12} lg={2}>
        <FormikTextInput
          type="number"
          name="live_end_range"
          label="Live End Range"
          multiline={false}
          variant="outlined"
          size="small"
        />
      </Grid>

      <Grid item xs={12} lg={2}>
        <FormikSubmit submitText="CREATE" fullWidth />
      </Grid>
    </Grid>
  );
};

export default FormFields;
