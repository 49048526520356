import { useCallback, useEffect, useState } from 'react';
import { ConfigLevel, OttAdsConfig } from '../../API/OttAds/types';
import {
  createNewOTTAd,
  deleteOTTSpecificAd,
  getOTTAds,
  reorderOttAds,
  updateOTTAdsCTA,
  updateOTTAdsIsEnabled,
  updateOTTSpecificAdVisible,
} from '../../API/OttAds';

const useOttAds = (linkedEntityId: string, configLevel: ConfigLevel) => {
  const [ottAdsConfig, setOttAdsConfig] = useState<OttAdsConfig | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);

  const handleFetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getOTTAds(linkedEntityId, configLevel);
      setOttAdsConfig(data);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [linkedEntityId, configLevel]);

  const toggleEnabled = async (enabled: boolean) => {
    setSubmitting(true);
    try {
      const data = await updateOTTAdsIsEnabled(
        linkedEntityId,
        configLevel,
        enabled,
      );
      setOttAdsConfig(data);
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const createNewAd = async (image: File) => {
    setSubmitting(true);
    try {
      const data = await createNewOTTAd(linkedEntityId, configLevel, image);
      setOttAdsConfig(data);
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const updateAdCTA = async (adId: string, cta: string) => {
    setSubmitting(true);
    try {
      const data = await updateOTTAdsCTA({
        linkedEntityId,
        configLevel,
        adId,
        cta,
      });
      setOttAdsConfig(data);
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const deleteAd = async (adId: string) => {
    setSubmitting(true);
    try {
      const data = await deleteOTTSpecificAd(linkedEntityId, adId);
      setOttAdsConfig(data);
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const toggleAdVisible = async (adId: string, visible: boolean) => {
    setSubmitting(true);
    try {
      const data = await updateOTTSpecificAdVisible(
        linkedEntityId,
        adId,
        visible,
      );
      setOttAdsConfig(data);
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const reorderAds = async (adsOrderIds: string[]) => {
    setSubmitting(true);
    try {
      const data = await reorderOttAds(
        linkedEntityId,
        configLevel,
        adsOrderIds,
      );
      setOttAdsConfig(data);
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    handleFetch();
  }, [linkedEntityId, configLevel]);

  return {
    ottAdsConfig,
    isLoading,
    error,
    refetch: handleFetch,
    toggleEnabled,
    createNewAd,
    updateAdCTA,
    deleteAd,
    toggleAdVisible,
    submitting,
    reorderAds,
  };
};

export default useOttAds;
