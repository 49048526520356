import { useCallback, useEffect, useState } from 'react';
import {
  EventRangeRequest,
  ViewsOverrideConfig,
} from '../../API/ViewsOverride/types';
import {
  deleteEventRangeConfig,
  getViewsOverrideConfig,
  toggleViewsOverrideConfigEnabled,
  updateEventRangeConfig,
} from '../../API/ViewsOverride';

const useViewsOverride = () => {
  const [
    viewsOverrideConfig,
    setViewsOverrideConfig,
  ] = useState<ViewsOverrideConfig | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);

  const handleFetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getViewsOverrideConfig();
      setViewsOverrideConfig(data);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const toggleEnabled = async (enabled: boolean) => {
    setSubmitting(true);
    try {
      const data = await toggleViewsOverrideConfigEnabled(enabled);
      setViewsOverrideConfig(data);
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleUpdateEventRangeConfig = async (args: EventRangeRequest) => {
    setSubmitting(true);
    try {
      const data = await updateEventRangeConfig(args);
      setViewsOverrideConfig(data);
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteEventRangeConfig = async (eventId: string) => {
    setSubmitting(true);
    try {
      const data = await deleteEventRangeConfig(eventId);
      setViewsOverrideConfig(data);
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return {
    viewsOverrideConfig,
    isLoading,
    error,
    submitting,
    toggleEnabled,
    handleUpdateEventRangeConfig,
    handleDeleteEventRangeConfig,
    refetch: handleFetch,
  };
};

export default useViewsOverride;
