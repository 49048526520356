import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { CATEGORY_KEY_VALUE_LIST_BY_FEED_TYPE } from '../../../../Components/ManagedHomeFeed/SportsHeader/utils';
import SportCard from '../../../../Components/ManagedHomeFeed/SportsHeader/SportCard';
import useOTTAdsStore from '../store/useOTTAdsStore';

const OttSportList = () => {
  const { selectedCategory, setSelectedCategory } = useOTTAdsStore();

  return (
    <Grid container spacing={3}>
      {CATEGORY_KEY_VALUE_LIST_BY_FEED_TYPE.HOME_FEED.map(sport => {
        return (
          <SportCard
            sport={sport}
            selected={selectedCategory === sport.value}
            onClick={() => setSelectedCategory(sport.value)}
          />
        );
      })}
    </Grid>
  );
};

export default OttSportList;
