import React, { useState } from 'react';
import { Box, Button, Input } from '@material-ui/core';

type Props = {
  handleUpload: (image: File) => void;
};

const UploadAdForm: React.FC<Props> = ({ handleUpload }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file && file.type.startsWith('image/')) {
      setSelectedFile(file);
    }
  };

  const handleSubmit = () => {
    if (selectedFile) {
      handleUpload(selectedFile);
      setSelectedFile(null);
    }
  };

  return (
    <Box
      border="1px solid #333"
      p={1}
      bgcolor="rgba(0,0,0,0.15)"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderRadius={5}
    >
      <input accept="image/*" type="file" onChange={handleFileChange} />
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={handleSubmit}
        disabled={!selectedFile}
      >
        Upload
      </Button>
    </Box>
  );
};

export default UploadAdForm;
