enum ConfigLevel {
  MATCH = 'MATCH',
  EVENT = 'EVENT',
  CHANNEL = 'CHANNEL',
  SPORT = 'SPORT',
}

export type AdsData = {
  ads_img_key: string;
  ads_cta: string;
  is_enabled: boolean;
  is_visible: boolean;
};

export interface OttAdsConfig {
  linked_entity_id: string;
  config_level: ConfigLevel;
  is_enabled: boolean;
  ads_data: Record<string, AdsData>;
  ads_order_ids: string[];
}

export { ConfigLevel };
