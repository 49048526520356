import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AppBar from '../../Components/AppBar';
import {
  CHANNEL_MANAGEMENT,
  TV,
  MATCH_MANAGEMENT,
  ATHLETEMANAGMENT,
  TOURNAMENT,
  EVENTS,
  STREAMS,
  ARTICLES,
  PERMISSIONS,
  HEADER_CONTENT,
  APPROVALS,
  STREAMING_SERVER,
  USERS,
  TEAMS,
  PASS_CHECK,
  MANAGED_HOME_FEED,
  JIO_SPORTS_HUB_FEED,
  PIXELLOT_TENANTS,
  SERVER_COSTS,
  CP_FIXED_LINKS,
  VIEWS_OVERRIDE,
  OTT_ADS,
} from '../../Utils/routeNames';
import ChannelManagement from './ChannelManagement';
import Home from './Home/Home';
import MatchManagement from './MatchManagement/MatchManagement';
import AthleteManagement from './AthleteManagement';
import TeamsManagement from './Teams/TeamsManagement';
import TournamentManagement from './TournamentManagment/TournamentManagement';
import EventsManagement from './EventsManagement';
import StreamsManagement from './StreamsManagement';
import Articles from './Articles';
import Permission from './PermissionManagment';
import HeaderContent from './HeaderContent/HeaderContent';
import ApprovalsSearch from './Approvals/ApprovalsSearch/ApprovalsSearch';
import StreamingServer from './StreamingServer/StreamingServer';
import Users from './Users/Users';
import PassCheck from './PassCheck/PassCheck';
import ManagedHomeFeed from './ManagedHomeFeed';
import PixellotTenants from './Tenants/PixellotTenants';
import ServerCosts from './ServerCosts/ServerCosts';
import FixedCloudProdLinksManager from './FixedCloudProdLinksManager/FixedCloudProdLinksManager';
import ViewsOverride from './ViewsOverride';
import OttAds from './OttAds';

const MainContainer = () => {
  return (
    <div>
      <Router>
        <AppBar />
        <Switch>
          <Route path={`/${CHANNEL_MANAGEMENT}`}>
            <ChannelManagement />
          </Route>
          <Route path={`/${MATCH_MANAGEMENT}`}>
            <MatchManagement />
          </Route>
          <Route path={`/${TV}`}>
            <div>TV</div>
          </Route>
          <Route path={`/${ATHLETEMANAGMENT}`}>
            <AthleteManagement />
          </Route>
          <Route path={`/${TOURNAMENT}`}>
            <TournamentManagement />
          </Route>
          <Route path={`/${TEAMS}`}>
            <TeamsManagement />
          </Route>
          <Route path={`/${EVENTS}`}>
            <EventsManagement />
          </Route>
          <Route path={`/${STREAMS}`}>
            <StreamsManagement />
          </Route>
          <Route path={`/${ARTICLES}`}>
            <Articles />
          </Route>
          <Route path={`/${PERMISSIONS}`}>
            <Permission />
          </Route>
          <Route path={`/${HEADER_CONTENT}`}>
            <HeaderContent />
          </Route>
          <Route path={`/${APPROVALS}`}>
            <ApprovalsSearch />
          </Route>
          <Route path={`/${STREAMING_SERVER}`}>
            <StreamingServer />
          </Route>
          <Route path={`/${USERS}`}>
            <Users />
          </Route>
          <Route path={`/${MANAGED_HOME_FEED}`}>
            <ManagedHomeFeed feedType="HOME_FEED" />
          </Route>
          <Route path={`/${JIO_SPORTS_HUB_FEED}`}>
            <ManagedHomeFeed feedType="JIO_SPORTS_HUB" />
          </Route>
          <Route path={`/${PIXELLOT_TENANTS}`}>
            <PixellotTenants />
          </Route>
          <Route path={`/${CP_FIXED_LINKS}`}>
            <FixedCloudProdLinksManager />
          </Route>
          <Route path={`/${SERVER_COSTS}`}>
            <ServerCosts />
          </Route>
          <Route path={`/${PASS_CHECK}`}>
            <PassCheck />
          </Route>
          <Route path={`/${VIEWS_OVERRIDE}`}>
            <ViewsOverride />
          </Route>
          <Route path={`/${OTT_ADS}`}>
            <OttAds />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

MainContainer.propTypes = {
  loginStatusHandler: PropTypes.func.isRequired,
};

export default MainContainer;
