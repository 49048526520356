import React from 'react';
import { Box, Switch, Typography } from '@material-ui/core';

type Props = {
  enabled: boolean;
  onChange: (f: boolean) => void;
};

const OTTAdsToggle: React.FC<Props> = ({ enabled, onChange }) => {
  return (
    <Box
      border="1px solid #333"
      p={1}
      py={0}
      bgcolor="rgba(0,0,0,0.15)"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderRadius={5}
    >
      <Typography variant="subtitle2">Enabled</Typography>

      <Switch checked={enabled} onChange={e => onChange(e.target.checked)} />
    </Box>
  );
};

export default OTTAdsToggle;
