import React from 'react';
import { useParams } from 'react-router-dom';
import useOttAds from '../../../../customHooks/OttAds/useOttAds';
import { ConfigLevel } from '../../../../API/OttAds/types';
import { Box, Container, LinearProgress } from '@material-ui/core';
import OTTAdsToggle from '../../OttAds/components/OTTAdsToggle';
import UploadAdForm from '../../OttAds/components/UploadAdForm';
import OttAdsTable from '../../OttAds/components/OttAdsTable';

const StreamOTTAds = () => {
  const { streamId } = useParams();
  const {
    ottAdsConfig,
    isLoading,
    toggleEnabled,
    createNewAd,
    toggleAdVisible,
    updateAdCTA,
    deleteAd,
    error,
    submitting,
    reorderAds,
  } = useOttAds(streamId, ConfigLevel.MATCH);

  if (isLoading || error || !ottAdsConfig) return null;

  return (
    <Box>
      <LinearProgress
        color="secondary"
        style={{ opacity: isLoading || submitting ? 1 : 0 }}
      />

      <Box
        p={3}
        style={{
          pointerEvents: isLoading ? 'none' : 'all',
        }}
      >
        <Box mt={3} />

        {ottAdsConfig && (
          <Container maxWidth="md">
            <Box display="flex" flexDirection="column" style={{ gap: 10 }}>
              <OTTAdsToggle
                enabled={ottAdsConfig.is_enabled}
                onChange={enabled => toggleEnabled(enabled)}
              />

              <UploadAdForm handleUpload={createNewAd} />

              <OttAdsTable
                adsData={ottAdsConfig.ads_data}
                handleToggleVisible={toggleAdVisible}
                updateAdCTA={updateAdCTA}
                deleteAd={deleteAd}
                ads_order_ids={
                  ottAdsConfig.ads_order_ids.length <
                  Object.keys(ottAdsConfig.ads_data).length
                    ? Object.keys(ottAdsConfig.ads_data)
                    : ottAdsConfig.ads_order_ids
                }
                reorderAds={reorderAds}
              />
            </Box>
          </Container>
        )}
      </Box>
    </Box>
  );
};

export default StreamOTTAds;
