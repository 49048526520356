import axiosInstance from '../../axiosInstance';

const getEvents = async (
  name = '',
  sport = '',
  state = '',
  event_id = '',
  preferred_section = '',
  page = 1,
  start_date = '',
  end_date = '',
  creator_id = '',
  ids = '',
) => {
  const response = await axiosInstance.get(`/api/admin/v2/events/`, {
    params: {
      page,
      name,
      state,
      sport,
      event_id,
      cache: 'false',
      preferred_section,
      from_timestamp: start_date,
      to_timestamp: end_date,
      creator_id,
      ids,
    },
  });

  const { data } = response.data;

  return data;
};

export default getEvents;
